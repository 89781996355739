import React from "react";
import styled from "styled-components";
import NukaCarousel from "nuka-carousel";
import * as Icon from "./Icon";
import Theme from "../Theme";
import * as Widget from "./Widget";

class BottomControl extends React.Component {
  render() {
    let { currentSlide, goToSlide, slideCount } = this.props;
    let indexes = Array.from(Array(slideCount).keys());
    return (
      <BottomControlWrapper>
        {indexes.map(index => (
          <Dot onClick={() => goToSlide(index)} key={index}>
            {index === currentSlide ? (
              <Icon.CircleEmpty
                color={Theme.colors.black}
                style={{ width: 16, height: 16 }}
              />
            ) : (
              <Icon.Circle
                color={Theme.colors.black}
                style={{ width: 12, height: 12 }}
              />
            )}
          </Dot>
        ))}
      </BottomControlWrapper>
    );
  }
}

const BottomControlWrapper = styled.div`
  width: 200px;
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Dot = styled.div`
  cursor: pointer;
  margin: 0 3px;
  display: flex;
  align-items: center;
`;

class Carousel extends React.Component {
  static defaultProps = {
    banners: [
      {
        title: "DEMO ONLY",
        titleStyle: {
          fontSize: "5em",
          fontWeight: "800",
          letterSpacing: 0,
          color: Theme.colors.yellow
        },
        widget: () => (
          <Widget.Center>
            <Widget.AnimateButton>LEARN MORE WITH MERCK!</Widget.AnimateButton>
          </Widget.Center>
        ),

        image:
          "https://thechalkboardmag.com/wp-content/uploads/2015/06/beyonce-tumblr-kale-sweatshirt-718x580.jpg"
      },
      {
        title: "DEMO ONLY",
        titleStyle: {
          fontSize: "6em",
          fontWeight: "800",
          letterSpacing: 0,
          color: Theme.colors.pink
        },
        widget: () => (
          <Widget.Center>
            <Widget.AnimateButton>LEARN MORE WITH MERCK!</Widget.AnimateButton>
          </Widget.Center>
        ),
        image:
          "https://s.yimg.com/ny/api/res/1.2/pCju2Zoz3tPQ1_JGtS_h8Q--~A/YXBwaWQ9aGlnaGxhbmRlcjtzbT0xO3c9MTI4MDtoPTk2MA--/http://media.zenfs.com/en-US/homerun/etonline.tv/12a5b353b6fd4fd213d6994b6e233805"
      }
    ]
  };
  render() {
    let { banners } = this.props;
    return (
      <NukaCarousel
        width="100%"
        height="100vh"
        autoplay={false}
        pauseOnHover={true}
        renderBottomCenterControls={props => <BottomControl {...props} />}
        renderCenterLeftControls={({ previousSlide }) => (
          <ControlWrapper>
            <Icon.KeyboardArrowLeft
              onClick={previousSlide}
              color={Theme.colors.black}
              style={{ width: 60, height: 60 }}
            />
          </ControlWrapper>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <ControlWrapper>
            <Icon.KeyboardArrowRight
              onClick={nextSlide}
              color={Theme.colors.black}
              style={{ width: 60, height: 60 }}
            />
          </ControlWrapper>
        )}
      >
        {banners.map((banner, idx) => (
          <Banner key={idx} src={banner.image}>
            <div className="title" style={banner.titleStyle || {}}>
              {banner.title}
            </div>
            {banner.widget()}
          </Banner>
        ))}
      </NukaCarousel>
    );
  }
}

const Banner = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${props => props.src});
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > .title {
    color: white;
    font-size: 50px;
    text-align: center;
    max-height: 80%;
    max-width: 100vw;
    overflow: hidden;
    white-space: pre-line;
  }
`;

const ControlWrapper = styled.div`
  padding: 50px;
`;

export default Carousel;
