import React from "react";
import styled from "styled-components";
import { withPage } from "../Page";
import Theme from "../Theme";
import * as Icon from "../Components/Icon";
import MainFunctionSection from "../Components/MainFunctionSection";
import { connect } from "react-redux";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import Carousel from "../Components/Carousel";
import LoginDialog from "../Components/LoginDialog";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false
    };
  }
  render() {
    let { profile, appActions } = this.props;
    let { showDialog } = this.state;

    return (
      <Wrapper>
        {showDialog && (
          <LoginDialog
            onClose={() => this.setState({ showDialog: false })}
            callback={() => this.setState({ showDialog: false })}
          />
        )}
        <img className="site-logo" src="/images/logo.png" />
        {/* <div className="side-bar">
          <Icon. color={Theme.colors.black} font-size="small"style={{ fontSize: 40 }}/>
        </div> */}

        {profile ? (
          <div className="site-profile" onClick={() => appActions.logout()}>
            登出
          </div>
        ) : (
          <div
            className="site-profile"
            onClick={() => this.setState({ showDialog: true })}
          >
            登入
          </div>
        )}

        <Carousel />
        <MainFunctionSection profile={profile} />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  min-height: calc(100vh - 60px);
  background-color: #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .site-logo {
    height: 20px;
    position: absolute;
    left: 100px;
    top: 40px;
    z-index: 1;
    @media screen and (max-width: 400px) {
      left: 50%;
      top: 20px;
      transform: translateX(-50%);
    }
  }

  & > .site-profile {
    height: 25px;
    position: absolute;
    right: 100px;
    top: 35px;
    z-index: 1;
    background-color: ${Theme.colors.blue};
    box-shadow: 4px 5px 0px 0px ${Theme.colors.purple};
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;
    transition: all 0.2s ease-in;
    &:hover {
      box-shadow: none;
      transform: translate(4px, 5px);
      transition: all 0.2s ease-in;
    }
    @media screen and (max-width: 400px) {
      right: 10px;
      top: 15px;
    }
  }

  & > .side-bar {
    width: 30px;
    height: 120px;
    background-color: ${Theme.colors.pink};
    position: fixed;
    right: 50px;
    top: 50vh;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: (() => {
        let profile;
        if (ownProps.location.pathname.indexOf("/mobile-patient") === 0) {
          profile = Selectors.getPatient(state);
        } else {
          profile = Selectors.getLoginUser(state);
        }
        return profile;
      })()
    }),
    ActionCreator
  )(HomePage)
);
