import React from "react";
import styled from "styled-components";
import Theme from "../Theme";
import LoginDialog from "../Components/LoginDialog";
import * as Widget from "./Widget";

const funcs = [
  {
    image:
      "https://static.wixstatic.com/media/541f30_4e09d6b3bf0e40d98474ce863128b8af~mv2.png/v1/fill/w_734,h_406,al_c,q_85,usm_0.66_1.00_0.01/page-1-5.webp",
    title: "身高預測計算機",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
    link:
      "https://wd.vghtpe.gov.tw/gcc/Image/growthPercentile/growth_percentile_20150505_01.html",
    css: `
      & .image-wrapper >.image{
        @media screen and (min-width: 1000px){
          margin-left: -80px;
          margin-top: 30px;
        }
      }
    `
  },
  {
    image:
      "https://static.wixstatic.com/media/541f30_eaa8e775aadb4ad584a9dd68acb713c5~mv2.png/v1/fill/w_466,h_650,al_c,q_85,usm_0.66_1.00_0.01/page-1-3.webp",
    title: "回診預約掛號",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
    link: "redirect/",
    css: `
      & .image-wrapper > .image{
        width: 65%;
        margin-top: -60px;
        margin-left: 70px;
          @media screen and (min-width: 1000px){
          margin-top: -80px;
          margin-left: 20px;
        }
      }
    `
  },
  {
    image:
      "https://static.wixstatic.com/media/541f30_de7169dbcacd4310a72457f95f4b5c20~mv2.png/v1/fill/w_524,h_566,al_c,q_85,usm_0.66_1.00_0.01/page-1-1.webp",
    title: "衛教影音資訊",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
    link: "videos/",
    css: `
      & .image-wrapper > .image{
        width: 65%;
        margin-left: 20px;
      }
    `
  },
  {
    image:
      "https://static.wixstatic.com/media/541f30_3ad3e38c989947598c0e2cbda3f26bfc~mv2.png/v1/fill/w_522,h_566,al_c,q_85,usm_0.66_1.00_0.01/page-1-2.webp",
    title: "帥建成長手冊",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
    link:
      "https://drive.google.com/file/d/1kUN3OoUqVew-8huuK23M9g1Bi8DY2vT4/view?usp=drive_open",
    css: `
      & .image-wrapper > .image{
        width: 70%;
        margin-left: 10px;
      }
    `
  },
  {
    image:
      "https://static.wixstatic.com/media/541f30_36e570b0dd3542e69f9687776413b424~mv2.png/v1/fill/w_522,h_406,al_c,q_85,usm_0.66_1.00_0.01/page-1-4.webp",
    title: "個人健康紀錄",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
    link: "profile/",
    css: `
      & .image-wrapper > .image{
        width: 70%;
        margin-top: 30px;
      }
    `
  }
];

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false
    };
  }
  render() {
    let { func } = this.props;
    let { showDialog } = this.state;
    return func ? (
      <ItemWrapper css={func.css}>
        {showDialog && (
          <LoginDialog
            onClose={() => this.setState({ showDialog: false })}
            callback={() => {
              this.setState({ showDialog: false });
              window.open(func.link, "_self");
            }}
          />
        )}
        <div className="image-wrapper">
          <img className="image" src={func.image} />
        </div>
        <h2 className="title">{func.title}</h2>
        <div className="desc">{func.desc}</div>
        <Widget.AnimateButton
          onClick={() => this._onClick(func.link)}
          bgColor={Theme.colors.purple}
          hoverBgColor={Theme.colors.pink}
        >
          View More
        </Widget.AnimateButton>
      </ItemWrapper>
    ) : (
      <Filler />
    );
  }

  _onClick = link => {
    let { profile } = this.props;
    if (!profile) {
      this.setState({ showDialog: true });
    } else {
      window.open(link, "_self");
    }
  };
}

const ItemWrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  & .image-wrapper {
    height: 250px;
    & .image {
      width: 100%;
    }
  }

  & > .title {
    color: ${Theme.colors.black};
    font-size: 25px;
    letter-spacing: 3px;
    margin-bottom: 15px;
  }

  & > .desc {
    color: ${Theme.colors.black};
    height: 100px;
  }

  ${props => props.css || ""}
`;
const Filler = styled.div`
  width: 400px;
`;

class MainFunctionSection extends React.Component {
  render() {
    let { profile } = this.props;
    return (
      <Wrapper>
        <h1>MAIN</h1>
        <h1>FUCNTIONS</h1>
        <Container>
          {this._filler(funcs).map((func, idx) => (
            <Item func={func} key={idx} profile={profile} />
          ))}
        </Container>
      </Wrapper>
    );
  }

  _filler = funcs => {
    if (funcs.length % 2 === 1) {
      return [...funcs, null];
    }
  };
}

const Wrapper = styled.div`
  flex: 1;
  max-width: 100vw;
  overflow: hidden;
  padding: 20px 10%;
  background-color: ${Theme.colors.yellow};

  > h1 {
    font-size: 5em;
    line-height: 0.8em;
    color: ${Theme.colors.purple};
    @media screen and (max-width: 400px) {
      font-size: 3em;
    }
  }
  @media screen and (max-width: 1000px) {
    padding: 30px;
  }

  @media screen and (max-width: 400px) {
    padding: 10px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
export default MainFunctionSection;
