import React from "react";
import Dialog from "./Dialog";
import styled from "styled-components";
import * as Widget from "./Widget";
import ActionCreator from "../ActionCreator";
import { connect } from "react-redux";
import ManualTokenLogin from "./ManualTokenLogin";
import Theme from "../Theme";
import * as Icon from "./Icon";

class LoginDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { onClose, callback } = this.props;
    return (
      <Dialog extraStyle={style.wrapper}>
        <Button onClick={onClose}>
          <Icon.Close color="white" />
        </Button>
        <ManualTokenLogin callback={callback} />
      </Dialog>
    );
  }
}

const Button = styled.div`
  background-color: ${Theme.colors.purple};
  border-radius: 5px;
  color: white;
  padding: 5px;
  line-height: 0;
  cursor: default;
  position: absolute;
  right: 30px;
  top: 30px;
`;

const style = {
  wrapper: {
    height: "50vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
};

export default LoginDialog;
